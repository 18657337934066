@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap';

body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	padding-top: 100px;
	color: #707070;
}

p {
	font-size: 16px;
	margin: 0;
	padding: 0;
}

i {
	font-size: 13px;
}

h1 {
	font-size: 25px;
	margin: 0;
	padding: 0;
}

strong {
	font-weight: 700;
}

.shadow {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.main-container {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.left-container {
	border-radius: 15px;
}

.space-container {
	width: 30px;
	height: 30px;
}

.right-container {
	border-radius: 15px;
	max-width: 650px;
}

.title-container {
	padding: 30px 30px;
	border-radius: 15px;
}

.body-container {
	padding: 30px 30px 40px 30px;
	border-radius: 15px;
	background-color: #fff;
}

.form-container {
	padding: 30px 200px 40px 200px;
	margin-top: -20px;
	background-color: #fff;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.socials-container {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding: 20px;
	border-radius: 15px;
	display: flex;
	align-items: center;
	min-width: 0;
}

.social-button {
	display: flex;
	border-radius: 50%;
	margin-right: 5px;
	margin-left: 5px;
	height: 45px;
	width: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.social-button:hover {
	background-color: #eee;
}

.form-container textarea {
	resize: none;
	background-color: #eeeeee;
	border: none;
	outline: none;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	padding: 10px 20px;
	border-radius: 8px;
	margin-bottom: 10px;
	min-width: 250px;
}

::placeholder {
	color: #c9c9c9;
}

.form-container input {
	background-color: #eeeeee;
	border: none;
	outline: none;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	padding: 10px 20px;
	border-radius: 8px;
	margin-bottom: 10px;
	min-width: 250px;
}

.left-container {
	display: flex;
	padding: 35px 35px;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.name-container {
	margin-top: 10px;
	margin-bottom: 20px;
}

.nav-list {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
}

.nav-list-item {
	margin-bottom: 10px;
	list-style-type: none;
}

.avatar-image {
	width: 130px;
	border-radius: 50%;
	border-width: 7px;
	border-color: #eeeeee;
	border-style: solid;
}

.nav-button {
	width: 100px;
}

.button {
	text-decoration: none;
	color: #707070;
	font-size: 14px;
	padding: 8px 30px;
	border-radius: 12px;
	background-color: #e5e5e5;
	font-weight: 700;
	display: inline-block;
}

.button:hover {
	background-color: #d0d0d0;
}

.footer {
	margin-top: 50px;
	margin-bottom: 50px;
	text-align: center;
}

.footer-link {
	color: #707070;
	font-weight: 400;
}

.projects-container {
	border-radius: 15px;
	min-width: 650px;
	margin-top: -40px;
	background-color: #fff;
}

.project-item-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 15px;
	margin-top: 20px;
	font-weight: 400;
	background-color: #fff;
}

.project-left-container {
	display: flex;
	align-items: center;
}

.project-image {
	width: 35px;
	border-radius: 8px;
}

.project-name-container {
	margin-left: 20px;
}

.project-button {
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 7px;
	padding-bottom: 5px;
	cursor: pointer;
}

.project-description-container {
	font-weight: 400;
	padding: 20px 30px 30px 30px;
}

.form-submit-button {
	font-size: 12px;
	padding: 8px 30px;
}

.project-button img {
	filter: invert(50%) sepia(3%) saturate(9%) hue-rotate(314deg) brightness(86%) contrast(87%);
}

/**
filter: invert(99%) sepia(1%) saturate(5039%) hue-rotate(185deg) brightness(125%) contrast(87%);
**/
@media only screen and (max-width: 1000px) {
	body {
		padding-top: 20px;
	}
	.main-container {
		flex-direction: column;
		align-items: stretch;
	}
	.left-container {
		margin-left: 50px;
		margin-right: 50px;
	}
	.nav-list {
		flex-direction: row;
	}
	.nav-list-item {
		margin-left: 5px;
		margin-right: 5px;
	}
	.right-container {
		margin-left: 50px;
		margin-right: 50px;
		max-width: none;
	}
	.projects-container {
		min-width: 0;
	}
}

@media only screen and (max-width: 700px) {
	.nav-list {
		flex-direction: column;
	}
	.nav-list-item {
		margin-left: 0px;
		margin-right: 0px;
	}
	.right-container {
		margin-left: 10px;
		margin-right: 10px;
	}
	.left-container {
		margin-left: 10px;
		margin-right: 10px;
		width: none;
	}
	.project-button image {
		height: 14px;
	}
	.project-button {
		padding-left: 15px;
		padding-right: 15px;
	}
	.form-container input {
		min-width: 200px;
	}
	.form-container textarea {
		min-width: 200px;
	}
	.form-container {
		padding-left: 20px;
		padding-right: 20px;
	}
}
.socials-container {
	padding-left: 0px;
	padding-top: 10px;
	padding-right: 0px;
	box-shadow: none;
}
